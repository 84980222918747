@charset "UTF-8";
html {
  font-size: calc(10em / 16);
  color: #2A2A2B;
  -webkit-font-feature-settings: "palt" 1;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.1em; }

body {
  font-family: "游ゴシック",Yu Gothic,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,sans-serif; }

main {
  background-image: url(/img/common/bg.jpg); }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

ul {
  list-style: none; }

a {
  text-decoration: none; }

figure {
  margin: 0; }

picture {
  display: block; }

.mod-arrowBtn {
  font-size: 1.8rem;
  font-family: "Noto Serif JP",serif;
  display: inline-block;
  color: #CB3A3E;
  border-bottom: 1px solid #CB3A3E;
  position: relative;
  padding-right: 2.5rem;
  padding-bottom: 1.2rem; }

.mod-arrowBtn:after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.4rem;
  background-image: url(/img/common/arrow-head.svg);
  position: absolute;
  bottom: -1px;
  right: 0; }

.mod-planContent {
  padding: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1) 0;
  border-bottom: 1px solid #CB3A3E;
  position: relative;
  background-color: #FFF; }

.mod-planContent:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 1px;
  background-color: #CB3A3E; }

.mod-planContent__title {
  display: flex;
  justify-content: center;
  font-family: "Noto Serif JP",serif;
  font-size: 2.1rem;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; }

.mod-planContent__title > span {
  flex: auto 0 0;
  position: relative;
  z-index: 0;
  padding: 0 2rem; }

.mod-planContent__title > span:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: -1;
  height: 1rem;
  transform: translateY(-1px);
  background-color: #FFF; }

.mod-planContent__title01 {
  font-weight: normal; }

.mod-planContent__cont {
  display: flex;
  justify-content: space-between; }

.mod-planContent__item {
  flex-basis: calc( ( 100% - 6.7rem * 2) / 3);
  display: flex;
  flex-direction: column; }

.mod-planContent__item:before {
  align-self: center;
  content: "";
  display: block;
  width: 7.4rem;
  height: 8rem;
  margin-bottom: 2rem;
  background-size: cover;
  background-repeat: no-repeat; }

.mod-planContent__item + .mod-planContent__item {
  position: relative; }

.mod-planContent__item + .mod-planContent__item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3.4rem;
  width: 1px;
  background-image: -moz-linear-gradient(0deg, #B1B1B1 0, #B1B1B1 2px, transparent 2px, transparent 4px, #B1B1B1 4px, #B1B1B1 6px, transparent 6px);
  background-image: -webkit-linear-gradient(0deg, #B1B1B1 0, #B1B1B1 2px, transparent 2px, transparent 4px, #B1B1B1 4px, #B1B1B1 6px, transparent 6px);
  background-image: linear-gradient(0deg, #B1B1B1 0, #B1B1B1 2px, transparent 2px, transparent 4px, #B1B1B1 4px, #B1B1B1 6px, transparent 6px);
  background-repeat: repeat-y;
  background-size: 100% 4px; }

.mod-planContent__item--isho:before {
  background-image: url(/img/common/mod-plan-content__icon--isho.svg); }

.mod-planContent__item--hair:before {
  background-image: url(/img/common/mod-plan-content__icon--hair.svg); }

.mod-planContent__item--photo:before {
  background-image: url(/img/common/mod-plan-content__icon--photo.svg); }

.mod-planContent__category {
  font-size: 1.8rem;
  font-family: "Noto Serif JP",serif;
  margin-bottom: 1.8rem;
  padding-left: 2.5rem;
  text-indent: -2.5rem; }

.mod-planContent__category:before {
  content: "";
  display: inline-block;
  margin-right: 0.5rem;
  width: 2rem;
  height: 1.5rem;
  background-image: url(/img/common/checkmark.svg);
  background-size: cover; }

.mod-planContent__categoryNote {
  font-size: 1.2rem;
  line-height: 1.3;
  text-indent: -1.2rem;
  padding-left: 1.2rem; }

.mod-planContent__categoryNote:before {
  content: "※"; }

.mod-planContent__categoryNote + .mod-planContent__categoryNote {
  margin-top: -1.4rem;
  margin-bottom: 1.8rem; }

.mod-planContent__category + .mod-planContent__categoryNote {
  margin-top: -1.2rem;
  margin-bottom: 1.8rem; }

.mod-planContent__listItem {
  font-size: 1.5rem;
  line-height: 1.4;
  text-indent: -1.1rem;
  padding-left: 1.1rem; }

.mod-planContent__listItem:before {
  content: "";
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 1rem;
  vertical-align: middle;
  transform: translateY(-50%);
  background-color: #666; }

.mod-planContent__listItem + .mod-planContent__listItem {
  margin-top: 1.3rem; }

.mod-planContent__listNote {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.45;
  margin-top: 0;
  padding-left: 0;
  text-indent: 0;
  color: #545454; }

.mod-planContent__listNote + .mod-planContent__listNote {
  margin-top: 0.3rem; }

.mod-planContent__listNote.isBracketed {
  text-indent: -0.1em; }

main > .mod-planContent, section > .mod-planContent {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 6rem;
  padding-right: 6rem; }

.mod-firstView + .mod-planContent {
  margin-top: 11rem; }

.mod-pageIntro + .mod-planContent {
  margin-top: 7.4rem; }

@media screen and (max-width: 770px) {
  .mod-planContent__cont {
    display: block; }
  .mod-planContent__item {
    align-items: center;
    padding-top: 1rem; }
  .mod-planContent__item + .mod-planContent__item {
    margin-top: 3rem;
    padding-top: 4rem; }
  .mod-planContent__item + .mod-planContent__item:after {
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: auto;
    bottom: auto;
    background-image: -moz-linear-gradient(90deg, #B1B1B1 0, #B1B1B1 2px, transparent 2px, transparent 4px, #B1B1B1 4px, #B1B1B1 6px, transparent 6px);
    background-image: -webkit-linear-gradient(90deg, #B1B1B1 0, #B1B1B1 2px, transparent 2px, transparent 4px, #B1B1B1 4px, #B1B1B1 6px, transparent 6px);
    background-image: linear-gradient(90deg, #B1B1B1 0, #B1B1B1 2px, transparent 2px, transparent 4px, #B1B1B1 4px, #B1B1B1 6px, transparent 6px);
    background-repeat: repeat-x;
    background-size: 4px 100%; }
  .mod-planContent__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 -1.3rem; }
  .mod-planContent__listItem {
    padding-left: 0;
    margin: 0 1.3rem;
    line-height: 1.7; }
  .mod-planContent__listItem + .mod-planContent__listItem {
    margin-top: 0; }
  .mod-planContent__listItem > br {
    display: none; }
  .mod-planContent__listItem:before {
    margin-right: 0.8rem; }
  .mod-planContent__listNote {
    font-size: 1.2rem;
    margin-left: 0.7rem;
    margin-top: 0; } }

@media screen and (max-width: 560px) {
  .mod-planContent {
    padding: 2.8rem 4rem 4rem; }
  main > .mod-planContent, section > .mod-planContent {
    width: 85%;
    padding: 2.8rem 4rem 4rem; }
  .mod-planContent__title {
    font-size: 1.6rem;
    padding: 0 1rem; }
  .mod-planContent__item:before {
    width: 5rem;
    height: 5.2rem;
    margin-bottom: 1.4rem; }
  .mod-planContent__item + .mod-planContent__item {
    margin-top: 2.5rem;
    padding-top: 2.5rem; }
  .mod-planContent__list {
    flex-direction: column;
    align-items: flex-start;
    margin: 0; }
  .mod-planContent__listItem {
    font-size: 1.2rem;
    margin: 0 1.1rem;
    text-indent: -0.8rem;
    padding-left: 0.8rem; }
  .mod-planContent__listItem:before {
    margin-right: 0.5rem; }
  .mod-planContent__listNote {
    padding-left: 0;
    margin-left: 0; }
  .mod-planContent__category {
    font-size: 1.6rem;
    margin-bottom: 1rem; }
  .mod-planContent__category:before {
    width: 1.6rem;
    height: 1.2rem; }
  .mod-planContent__category + .mod-planContent__categoryNote {
    margin-top: -0.8rem;
    margin-bottom: 1rem; }
  .mod-pageIntro + .mod-planContent {
    margin-top: 5rem; } }

.campaignSec {
  margin-top: 40px; }

@media screen and (min-width: 1501px) {
  .campaignSec {
    margin-top: 7.4rem; } }

.campaignSec__link {
  display: block;
  max-width: fit-content;
  margin: auto;
  transition: all 0.5s; }

.campaignSec__link:hover {
  opacity: 0.8;
  transition: all 0.5s; }

.campaignSec__img {
  text-align: center; }

.campaignSec__img .img {
  max-width: 650px;
  width: 75vw; }

@media screen and (max-width: 560px) {
  .campaignSec__img .img {
    width: 85vw; } }

@media screen and (max-width: 560px) {
  .campaignSec__img.pc {
    display: none; } }

.campaignSec__img.sp {
  display: none; }

@media screen and (max-width: 560px) {
  .campaignSec__img.sp {
    display: block; } }

.campaignDateSec {
  margin-top: 20px; }

.campaignDateSec .campaignDate {
  align-items: center;
  background-color: #fff;
  border: 1px solid #609159;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  max-width: 650px;
  margin: 0 auto;
  padding: 30px;
  width: 75vw; }

@media screen and (max-width: 560px) {
  .campaignDateSec .campaignDate {
    padding: 20px;
    width: 85vw; } }

.campaignDateSec .campaignDate__inner {
  text-align: center; }

.campaignDateSec .campaignDate__txt {
  color: #609159;
  font-size: 16px;
  line-height: 1.56; }

@media screen and (max-width: 560px) {
  .campaignDateSec .campaignDate__txt {
    font-size: 14px; } }

.campaignDateSec .campaignDate__memo {
  color: #609159;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: .05em;
  margin-top: 15px; }

@media screen and (max-width: 560px) {
  .campaignDateSec .campaignDate__memo {
    font-size: 12px;
    margin-top: 10px; } }

.campaignDateSec .campaignDate .sp-only {
  display: none; }

@media screen and (max-width: 560px) {
  .campaignDateSec .campaignDate .sp-only {
    display: block; } }

.mod-planCards:not(.swiper-wrapper) {
  display: flex;
  flex-wrap: wrap;
  list-style: none; }

main > .mod-planCards {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto; }

.mod-planCards__item {
  align-self: stretch;
  background-color: #FFF;
  height: auto;
  position: relative; }

.mod-planCards__item > a {
  color: #2A2A2B; }

.mod-planCards__txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 3rem 6rem;
  position: relative; }

.mod-planCards__img {
  background-color: #EEE; }

.mod-planCards__img > img {
  display: block;
  width: 100%; }

.mod-planCards__name {
  font-size: 2.2rem;
  line-height: 1.1;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  color: #CB3A3E; }

.mod-planCards__price {
  font-size: 2.6rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  margin-top: 1rem;
  transform: translateX(-0.1em); }

.mod-planCards__price--taxinc {
  font-size: 1.4rem !important;
  font-size: 14px !important;
  margin-top: 15px; }

@media screen and (max-width: 560px) {
  .mod-planCards__price--taxinc {
    margin-top: 10px !important; } }

.mod-planCards__cap {
  text-align: justify;
  max-width: 100%;
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 1.5; }

.mod-planCards__cap span {
  color: #cb3a3e; }

.mod-planCards__icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEE;
  padding-top: 2rem;
  margin-top: 2rem;
  box-sizing: content-box;
  width: 100%; }

.mod-planCards__icons > .mod-planIcon {
  flex: auto 1 1;
  padding: 0.8rem 0 0.8rem 3rem !important; }

.mod-planCards__icons > .mod-planIcon + .mod-planIcon {
  margin-left: 0; }

.mod-planCards__tag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #CB3A3E;
  padding: 1rem 0.1rem 1rem 1.6rem;
  color: #FFF;
  font-size: 1.5rem;
  line-height: 1;
  transform: translateY(-99%); }

.mod-planCards__tag > span {
  position: relative;
  z-index: 2; }

.mod-planCards__tag:before {
  content: "";
  display: block;
  background-color: #CB3A3E;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 2px;
  z-index: 0;
  transform: translateX(50%); }

.mod-planCards__tag:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.4rem 3.5rem 0;
  border-color: transparent transparent #CB3A3E;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform: translateX(99%); }

.mod-planCards__txt > .mod-btn {
  margin-top: 2rem;
  width: 100%; }

.mod-planCards__item > .com-userActions {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem; }

.mod-divideHeading + .mod-planCards {
  margin-top: 3rem; }

@media screen and (min-width: 1301px) {
  .mod-planCards__item:not(.swiper-slide) {
    flex-basis: calc((100% - 1rem*2 - 1px)/3); }
  .mod-planCards__item:not(.swiper-slide) + .mod-planCards__item:not(.swiper-slide):not(:nth-child(3n+1)) {
    margin-left: 1rem; }
  .mod-planCards__item:not(.swiper-slide):not(:nth-child(-n+3)) {
    margin-top: 2rem; } }

@media screen and (max-width: 1300px) and (min-width: 801px) {
  .mod-planCards__item:not(.swiper-slide) {
    flex-basis: calc((100% - 1rem*1 - 1px)/2); }
  .mod-planCards__item:not(.swiper-slide) + .mod-planCards__item:not(.swiper-slide):not(:nth-child(2n+1)) {
    margin-left: 1rem; }
  .mod-planCards__item:not(.swiper-slide):not(:nth-child(-n+2)) {
    margin-top: 2rem; } }

@media screen and (max-width: 800px) {
  .mod-planCards__item:not(.swiper-slide) + .mod-planCards__item:not(.swiper-slide) {
    margin-top: 2rem; } }

@media screen and (max-width: 560px) {
  main > .mod-planCards {
    width: 85%; }
  .mod-planCards__txt {
    padding: 2.8rem 2.5rem 5rem; }
  .mod-planCards__txt > .mod-btn {
    margin-top: 1rem; }
  .mod-planCards__name {
    font-size: 2rem; }
  .mod-planCards__price {
    font-size: 2rem;
    margin-top: 0.6rem; }
  .mod-planCards__cap {
    margin-top: 1rem; }
  .mod-planCards__icons {
    margin-top: 1.2rem;
    padding-top: 0.6rem; } }

.mod-planIcon {
  font-size: 1.3rem;
  line-height: 1;
  position: relative;
  padding-left: 3rem; }

.mod-planIcon:before {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: #CB3A3E;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; }

.mod-planIcon + .mod-planIcon {
  margin-left: 1.8rem; }

.mod-planIcon--isho:before {
  background-image: url(/img/common/mod-plan-cards__icon--isho.svg); }

.mod-planIcon--loc:before {
  background-image: url(/img/common/mod-plan-cards__icon--loc.svg); }

.mod-planIcon--album:before {
  background-image: url(/img/common/mod-plan-cards__icon--album.svg); }

.mod-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CB3A3E;
  padding: 1.8rem 3.5rem;
  color: #CB3A3E;
  background-color: #FFF;
  font-size: 1.5rem;
  font-family: "Noto Serif JP",serif;
  position: relative; }

.mod-btn:after {
  content: "";
  display: block;
  width: 2.6rem;
  height: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/common/arrow-tochange.svg);
  transform: translateY(-10%);
  margin-left: 1rem; }

.mod-btn[target=_blank]:before, .mod-btn[target=_blank]:after {
  content: "";
  width: 1.3rem;
  height: 1rem;
  background-color: transparent;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 3rem; }

.mod-btn[target=_blank]:before {
  border: 1px solid #CB3A3E;
  margin: -0.1rem -0.1rem 0 0;
  transform: translateY(-50%); }

.mod-btn[target=_blank]:after {
  border-bottom: 1px solid #CB3A3E;
  border-left: 1px solid #CB3A3E;
  transform: translate(-0.2rem, calc(-50% + 0.2rem));
  background-image: none; }

.mod-btn--line {
  border-right: none;
  border-left: none; }

.mod-btn--more {
  padding: 1.8rem 3.5rem 1.7rem 2rem; }

.mod-btn--more:before, .mod-btn--more:after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1px;
  background-color: #CB3A3E;
  position: absolute;
  top: 50%;
  right: 1.8rem; }

.mod-btn--more:before {
  transform: translateY(-50%); }

.mod-btn--more:after {
  transform: translateY(-50%) rotate(90deg); }

.mod-btn--justify {
  justify-content: space-between; }

.mod-btn--toback:after {
  content: none; }

.mod-btn--toback:before {
  content: "";
  display: block;
  width: 2.6rem;
  height: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/common/arrow-tochange.svg);
  transform: translateY(-10%) scaleX(-1);
  margin-right: 1rem; }

@media screen and (max-width: 560px) {
  .mod-btn {
    padding: 1.2rem 1.5rem;
    letter-spacing: 0; } }

.mod-optionDesc {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

main > .mod-optionDesc {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto; }

.mod-optionDesc__item {
  width: calc(( 100% - 5rem*2)/3); }

.mod-optionDesc__item > a, .mod-optionDesc__item--myplan {
  display: flex;
  flex-direction: column;
  align-items: center; }

.mod-optionDesc__item + .mod-optionDesc__item {
  position: relative; }

.mod-optionDesc__item:nth-child(2):before, .mod-optionDesc__item:nth-child(2):after, .mod-optionDesc__item:last-child:before, .mod-optionDesc__item:last-child:after {
  content: "";
  width: 2.7rem;
  height: 0.4rem;
  background-color: #CB3A3E;
  position: absolute;
  left: -2.5rem;
  top: 50%;
  transform: translate(-50%, -50%); }

.mod-optionDesc__item:nth-child(2):after {
  transform: translate(-50%, -50%) rotate(90deg); }

.mod-optionDesc__item:last-child:before {
  transform: translate(-50%, calc(-50% - 0.7rem)); }

.mod-optionDesc__item:last-child:after {
  transform: translate(-50%, calc(-50% + 0.7rem)); }

.mod-optionDesc__item--plan .mod-optionDesc__img {
  background-image: url(/img/common/mod-option-desc__plan--wh.svg); }

.mod-optionDesc__item--option .mod-optionDesc__img {
  background-image: url(/img/common/mod-option-desc__option--wh.svg); }

.mod-optionDesc__item--myplan .mod-optionDesc__img {
  background-image: url(/img/common/mod-option-desc__myplan.svg); }

.mod-optionDesc__img {
  content: "";
  display: block;
  width: 100%;
  position: relative;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat; }

.mod-optionDesc__img:before {
  content: "";
  display: block;
  padding-top: 90%; }

.mod-optionDesc__pseudoBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 22rem;
  font-size: 1.7rem;
  padding: 1.5rem 1rem;
  border-top: 1px solid #CB3A3E;
  border-bottom: 1px solid #CB3A3E;
  background-color: #FFF;
  color: #CB3A3E;
  margin-top: -1.8rem;
  position: relative;
  z-index: 1; }

.mod-optionDesc__pseudoBtn > span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #FFF;
  border: 1px solid #CB3A3E;
  line-height: 1;
  font-size: 1.3rem;
  padding: 1rem 1.8rem 0.9rem;
  border-radius: 10rem;
  transform: translate(-1.8rem, -80%); }

.mod-optionDesc__pseudoBtn > span:before {
  content: "";
  display: block;
  width: 1.8rem;
  height: 0.3rem;
  background-color: #FFF;
  position: absolute;
  bottom: 0rem;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, 50%); }

.mod-optionDesc__pseudoBtn > span:after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1rem;
  background: url(/img/common/mod-option-desc__tail.svg) 50% 50%/cover no-repeat;
  position: absolute;
  bottom: 0.3rem;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, 100%); }

.mod-optionDesc--inWhiteBg .mod-optionDesc__item--plan .mod-optionDesc__img {
  background-image: url(/img/common/mod-option-desc__plan.svg); }

.mod-optionDesc--inWhiteBg .mod-optionDesc__item--option .mod-optionDesc__img {
  background-image: url(/img/common/mod-option-desc__option.svg); }

.mod-sectionIntro + .mod-optionDesc, .mod-sectionTitle + .mod-optionDesc {
  margin-top: 5rem; }

@media screen and (max-width: 780px) {
  .mod-optionDesc {
    display: none; } }

main > .mod-simplePhotoGallery, section > .mod-simplePhotoGallery {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto; }

.mod-simplePhotoGallery__cont {
  display: flex;
  flex-wrap: wrap; }

.mod-simplePhotoGallery__store {
  display: none; }

.mod-simplePhotoGallery__item {
  display: block; }

.mod-simplePhotoGallery__item a {
  display: flex;
  flex-direction: column; }

.mod-simplePhotoGallery__item a + .com-userActions {
  margin-top: 1rem; }

.mod-simplePhotoGallery__img {
  display: block;
  position: relative; }

.mod-simplePhotoGallery__img:before {
  content: "";
  display: block;
  padding-top: 65%; }

.mod-simplePhotoGallery__img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%; }

.mod-simplePhotoGallery__viewmore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7rem;
  text-align: center;
  border: none;
  background: none;
  color: #CB3A3E;
  font-size: 1.8rem;
  position: relative;
  margin-top: 5.6rem;
  cursor: pointer;
  outline: none; }

.mod-simplePhotoGallery__viewmore--hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear; }

.mod-simplePhotoGallery__viewmore:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #CB3A3E;
  border-bottom: 1px solid #CB3A3E; }

.mod-simplePhotoGallery__viewmore:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  right: 0;
  border-left: 1px solid #CB3A3E;
  border-right: 1px solid #CB3A3E; }

.mod-simplePhotoGallery__viewmore > span {
  display: block;
  width: 100%;
  position: relative; }

.mod-simplePhotoGallery__viewmore > span:before, .mod-simplePhotoGallery__viewmore > span:after {
  content: "";
  display: block;
  width: 1.3rem;
  height: 1px;
  background-color: #CB3A3E;
  position: absolute;
  right: 4rem;
  top: 50%;
  transform: translateY(-50%); }

.mod-simplePhotoGallery__viewmore > span:after {
  transform: translateY(-50%) rotate(90deg); }

.mod-sectionTitle + .mod-simplePhotoGallery {
  margin-top: 5rem; }

@media screen and (min-width: 771px) {
  .mod-simplePhotoGallery__item {
    width: calc((100% - 5.6rem*2 - 1px)/3); }
  .mod-simplePhotoGallery__item:not(:nth-child(3n+1)) {
    margin-left: 5.6rem; }
  .mod-simplePhotoGallery__item:not(:nth-child(-n+3)) {
    margin-top: 2.5rem; } }

@media screen and (max-width: 770px) and (min-width: 561px) {
  .mod-simplePhotoGallery__item {
    width: calc((100% - 2.5rem*2 - 1px)/3); }
  .mod-simplePhotoGallery__item:not(:nth-child(3n+1)) {
    margin-left: 2.5rem; }
  .mod-simplePhotoGallery__item:not(:nth-child(-n+3)) {
    margin-top: 2.5rem; } }

@media screen and (max-width: 560px) {
  main > .mod-simplePhotoGallery, section > .mod-simplePhotoGallery {
    width: 85%; }
  .mod-simplePhotoGallery__item {
    width: calc(( 100% - 2rem - 1px) / 2); }
  .mod-simplePhotoGallery__item:not(:nth-child(2n+1)) {
    margin-left: 1rem; }
  .mod-simplePhotoGallery__item:not(:nth-child(-n+2)) {
    margin-top: 1.5rem; }
  .mod-simplePhotoGallery__viewmore {
    height: 6rem;
    margin-top: 3rem; }
  .mod-sectionTitle + .mod-simplePhotoGallery {
    margin-top: 2.5rem; } }

.mod-contactIntro {
  padding: 0 5% 0; }

.mod-contactIntro__title {
  font-size: 2.6rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  font-weight: normal;
  color: #CB3A3E;
  line-height: 1.5;
  text-align: center;
  position: relative;
  padding-bottom: 2.6rem; }

.mod-contactIntro__title:after {
  content: "";
  display: block;
  width: 3rem;
  height: 0.1rem;
  background-color: #CB3A3E;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%); }

.mod-contactIntro__heading {
  font-size: 2.6rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  font-weight: normal;
  color: #CB3A3E;
  line-height: 1.5;
  text-align: center;
  margin: 0; }

.mod-contactIntro__lead {
  font-size: 1.8rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  line-height: 1.9;
  text-align: center; }

.mod-contactIntro__title + .mod-contactIntro__lead {
  margin-top: 2.8rem; }

.mod-contactIntro__heading + .mod-contactIntro__lead {
  margin-top: 2.2rem; }

.mod-contactIntro__btns {
  width: 80%;
  max-width: 1200px;
  margin: 6rem auto 0; }

.mod-contactIntro__btns:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
  clear: both; }

.mod-contactIntro__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  float: left;
  width: calc(( 100% - 2.2rem*3 - 1px) / 2);
  height: 9.8rem;
  border-top: 1px solid #CB3A3E;
  border-bottom: 1px solid #CB3A3E;
  background-color: #FFF;
  font-family: "Noto Serif JP",serif; }

.mod-contactIntro__btn > a {
  color: #CB3A3E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.mod-contactIntro__btn + .mod-contactIntro__btn {
  margin-left: 2.2rem; }

.mod-contactIntro__btnTitle {
  font-size: 1.8rem;
  line-height: 1; }

.mod-contactIntro__btnEn {
  font-size: 1.3rem;
  line-height: 1;
  margin-top: 0.6rem; }

* + .mod-contactIntro {
  margin-top: 18rem; }

@media screen and (max-width: 960px) {
  .mod-contactIntro {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding: 0; }
  .mod-contactIntro__btns {
    width: 100%; } }

@media screen and (max-width: 770px) {
  .mod-contactIntro {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .mod-contactIntro__heading > br, .mod-contactIntro__lead > br {
    display: none; }
  .mod-contactIntro__heading {
    text-align: left; }
  .mod-contactIntro__lead {
    text-align: justify; }
  .mod-contactIntro__btns {
    margin-top: 4rem; } }

@media screen and (max-width: 770px) {
  .mod-contactIntro__title {
    font-size: 1.9rem; }
  .mod-contactIntro__heading {
    font-size: 2.1rem;
    text-align: left; }
  .mod-contactIntro__heading > br {
    display: none; }
  .mod-contactIntro__lead {
    font-size: 1.6rem;
    text-align: left; }
  .mod-contactIntro__lead > br {
    display: none; }
  .mod-contactIntro__title + .mod-contactIntro__lead {
    margin-top: 1.5rem; }
  .mod-contactIntro__heading + .mod-contactIntro__lead {
    margin-top: 1rem; }
  .mod-contactIntro__btns {
    width: 100%;
    margin-top: 1.5rem; }
  .mod-contactIntro__btn {
    width: 100%;
    border: none;
    border-left: 1px solid #CB3A3E;
    border-right: 1px solid #CB3A3E;
    height: 8.5rem; }
  .mod-contactIntro__btn + .mod-contactIntro__btn {
    margin-left: 0;
    margin-top: 1rem; }
  .mod-contactIntro__btnTitle {
    font-size: 1.7rem; }
  * + .mod-contactIntro {
    margin-top: 6rem; } }

.mod-notation {
  width: 80%;
  max-width: 1200px;
  line-height: 1;
  font-size: 1.2rem;
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  color: #5A5A5C;
  margin: 0 auto; }

.mod-notation:before {
  content: "※"; }

* + .mod-notation {
  margin-top: 1.5rem; }

.mod-foldList + .mod-notation {
  color: #CB3A3E; }

.mod-notation + .mod-foldList, .mod-notation + .mod-foldHead {
  margin-top: 6.5rem; }

@media screen and (max-width: 560px) {
  .mod-notation {
    width: 85%; }
  .mod-notation + .mod-foldList, .mod-notation + .mod-foldHead {
    margin-top: 5rem; } }

.mod-markerHeading {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.5;
  color: #CB3A3E;
  padding-bottom: 1rem;
  border-bottom: 1px solid #CB3A3E; }

main > .mod-markerHeading {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto; }

.mod-sectionTitle + .mod-markerHeading {
  margin-top: 8rem; }

@media screen and (max-width: 560px) {
  .mod-markerHeading {
    font-size: 1.7rem;
    padding-bottom: 0.5rem; }
  .mod-sectionTitle + .mod-markerHeading {
    margin-top: 3rem; } }

main {
  overflow: hidden; }

@media screen and (max-width: 770px) {
  main {
    padding-top: calc(5.6rem - 10px); } }

main > section:not(.heroSlide) + section {
  margin-top: 20rem; }

@media screen and (max-width: 960px) {
  main > section:not(.heroSlide) + section {
    margin-top: 6rem; } }

*[data-emergence=hidden] {
  opacity: 0;
  transform: translateY(1rem); }

*[data-emergence=visible] {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.8s ease-in-out; }

.concept {
  position: relative;
  padding-top: 12rem;
  margin-top: 20rem; }

.concept > .sectionTitle {
  position: absolute;
  top: 0;
  right: calc(( 100% - 80%) / 2);
  z-index: 1; }

@media screen and (min-width: 1500px) {
  .concept__img {
    width: calc( (1200px - 0px * ( 12 - 1 ) ) / 12 * 12 + ( 0px * ( 12 - 1 ) ) + ( 100% - 1200px ) / 2); } }

@media screen and (max-width: 960px) {
  .concept {
    padding-top: 15rem;
    padding-bottom: 6rem;
    background-color: rgba(255, 255, 255, 0.8); }
  .concept:before {
    content: none; }
  .concept .sectionTitle {
    margin-left: 2.5rem; } }

@media screen and (max-width: 770px) {
  .concept {
    padding-top: 0; }
  .concept .sectionTitle {
    top: 7rem;
    left: calc(( 100% - 85%) / 2); } }

@media screen and (max-width: 560px) {
  .concept {
    padding-top: 5rem; }
  .concept .sectionTitle {
    position: relative;
    top: auto;
    left: auto;
    margin-left: 0; } }

.conceptIntro {
  display: flex;
  position: relative;
  z-index: 0; }

.conceptIntro:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  background-color: #FFF; }

.conceptIntro__img {
  flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  flex-shrink: 0;
  max-width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  position: relative;
  z-index: 1; }

.conceptIntro__img > img {
  display: block;
  width: 100%; }

.conceptIntro__txt {
  align-self: flex-end;
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) ) * 1);
  padding-bottom: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  position: relative;
  text-align: justify;
  z-index: 0; }

.conceptIntro--01 {
  align-items: flex-end;
  padding-bottom: 5.6rem; }

.conceptIntro--01 .conceptIntro__txt {
  margin-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); }

.conceptIntro--01:before {
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 12 + ( 0px * ( 12 - 1 ) ) ) * 1);
  top: 0;
  bottom: 0;
  left: calc(( 100% - 80%) / 2);
  background-color: #FFF; }

.conceptIntro--01:after {
  content: "";
  display: block;
  width: 16rem;
  height: 16rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/sitetop/ume.svg);
  position: absolute;
  bottom: 0;
  left: calc(( 100% - 80%) / 2);
  transform: translate(-50%, 25%);
  z-index: 1; }

.conceptIntro--01 .conceptIntro__img {
  position: relative;
  z-index: 0; }

.conceptIntro--02 {
  position: relative;
  margin-top: 18rem;
  flex-direction: row-reverse; }

.conceptIntro--02 .conceptIntro__txt {
  max-width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 12 + ( 0px * ( 12 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 12 + ( 0px * ( 12 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  padding-top: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  padding-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  padding-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 7 + ( 0px * ( 7 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  background-color: #FFF; }

.conceptIntro--02 .conceptIntro__img {
  align-self: flex-end;
  flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) ) * 1);
  margin-right: calc(( 100% - 80%) / 2);
  margin-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * -1);
  margin-bottom: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); }

.conceptIntro--02 .conceptIntro__img:after {
  content: "";
  display: block;
  width: 8.8rem;
  height: 8.8rem;
  background-image: -moz-linear-gradient(0deg, #CB3A3E 0, #CB3A3E 1px, transparent 1px, transparent 5px, #CB3A3E 5px, #CB3A3E 6px, transparent 6px);
  background-image: -webkit-linear-gradient(0deg, #CB3A3E 0, #CB3A3E 1px, transparent 1px, transparent 5px, #CB3A3E 5px, #CB3A3E 6px, transparent 6px);
  background-image: linear-gradient(0deg, #CB3A3E 0, #CB3A3E 1px, transparent 1px, transparent 5px, #CB3A3E 5px, #CB3A3E 6px, transparent 6px);
  background-repeat: repeat-y;
  background-size: 100% 5px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%); }

@media screen and (max-width: 1360px) {
  .conceptIntro--01 {
    padding-bottom: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); }
  .conceptIntro--01 .conceptIntro__txt {
    padding-bottom: 0; }
  .conceptIntro--02 {
    margin-top: 16rem; }
  .conceptIntro--02 .conceptIntro__txt {
    padding-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1); }
  .conceptIntro--02 .conceptIntro__img {
    flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
    margin-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) + ( 100% - 80% ) / 2 ) * -1);
    margin-right: 0; } }

@media screen and (max-width: 960px) {
  .conceptIntro {
    width: 80%;
    margin: 0 auto;
    display: block; }
  .conceptIntro:before {
    content: none; }
  .conceptIntro__img {
    width: 100%;
    max-width: inherit; }
  .conceptIntro__img:before {
    padding-top: 60%; }
  .conceptIntro__txt {
    width: 100%;
    background-color: transparent;
    margin: 4.5rem 0 0 !important;
    padding: 0 !important; }
  .conceptIntro + .conceptIntro {
    margin-top: 4.5rem; }
  .sectionTitle + .conceptIntro .conceptIntro__txt {
    margin-left: 0;
    padding-bottom: 4.5rem; }
  .conceptIntro--01 {
    padding-bottom: 1rem; }
  .conceptIntro--01:after {
    content: none; }
  .conceptIntro--02 {
    padding-top: 0; }
  .conceptIntro--02 .conceptIntro__img {
    width: 100%;
    margin: 0 auto;
    position: static; }
  .conceptIntro--02 .conceptIntro__img:before {
    padding-top: 60%; }
  .conceptIntro--02 .conceptIntro__img:after {
    content: none; }
  .conceptIntro--02 .conceptIntro__txt {
    width: auto;
    max-width: inherit;
    margin-left: 0;
    background-color: transparent; } }

@media screen and (max-width: 770px) {
  .conceptIntro {
    width: 85%; } }

@media screen and (max-width: 560px) {
  .conceptIntro__txt {
    margin-top: 2.5rem !important; }
  .conceptIntro + .conceptIntro {
    margin-top: 3rem; } }

.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 3 + ( 0px * ( 3 - 1 ) ) ) * 1);
  max-width: 25rem;
  min-width: 15rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  background-color: #CB3A3E;
  color: #FFF;
  text-align: center;
  position: relative; }

.sectionTitle:before {
  content: "";
  display: block;
  padding-top: 100%; }

.sectionTitle__main {
  font-size: 2.4rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 90%; }

.sectionTitle__sub {
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 90%; }

@media screen and (max-width: 960px) {
  .sectionTitle__main {
    font-size: 1.6rem; }
  .sectionTitle__sub {
    font-size: 1.1rem; } }

@media screen and (max-width: 770px) {
  .sectionTitle {
    width: 85%;
    max-width: unset;
    min-width: unset;
    margin: auto auto 3.8rem !important;
    padding: 0;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: auto !important;
    transform: none !important;
    background-color: transparent;
    color: #CB3A3E; }
  .sectionTitle__main, .sectionTitle__sub {
    position: static;
    transform: none; }
  .sectionTitle__main {
    font-size: 2.6rem; }
  .sectionTitle__sub {
    font-size: 1.4rem;
    margin-top: 0.8rem; }
  .sectionTitle:before {
    content: none; } }

@media screen and (max-width: 560px) {
  .sectionTitle {
    margin-bottom: 3.5rem !important; }
  .sectionTitle__main {
    font-size: 2.2rem; }
  .sectionTitle__sub {
    font-size: 1.3rem;
    margin-top: 0.6rem; } }

.basic-heading {
  font-size: 2.35rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  font-weight: normal;
  color: #CB3A3E;
  line-height: 1.55;
  letter-spacing: 0.08em;
  margin: 0; }

.basic-lead {
  font-size: 1.8rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  line-height: 1.9;
  letter-spacing: 0.14em; }

.basic-lead + .mod-planContent {
  margin: 5rem auto 0;
  width: 80%; }

.basic-heading + .basic-lead {
  margin-top: 3.5rem;
  padding-top: 4.5rem;
  position: relative; }

.basic-heading + .basic-lead:before {
  content: "";
  display: block;
  width: 2.8rem;
  height: 1px;
  background-color: #CB3A3E;
  position: absolute;
  top: 0;
  left: 0; }

.basic-heading--centered {
  text-align: center; }

.basic-heading--centered + .basic-lead {
  text-align: center;
  padding-top: 0;
  margin-top: 2.2rem; }

.basic-heading--centered + .basic-lead:before {
  content: none; }

@media screen and (max-width: 1200px) {
  .basic-heading {
    font-size: 2.2rem; }
  .basic-lead {
    font-size: 1.7rem;
    line-height: 1.8; } }

@media screen and (max-width: 1100px) {
  .basic-heading {
    font-size: 2.1rem;
    line-height: 1.6; }
  .basic-lead {
    font-family: "Noto Serif JP",serif;
    font-weight: normal;
    color: #2A2A2B;
    font-size: 1.7rem;
    line-height: 1.9; }
  .basic-heading + .basic-lead {
    margin-top: 2rem;
    padding-top: 2.5rem;
    text-align: justify; }
  .basic-heading + .basic-lead > br {
    display: none; }
  .basic-heading--centered + .basic-lead {
    padding-top: 0; } }

@media screen and (max-width: 960px) {
  .basic-heading {
    font-size: 2.2rem;
    line-height: 1.6; }
  .basic-heading--centered {
    text-align: left; }
  .basic-lead {
    font-size: 1.7rem;
    line-height: 1.9; }
  .basic-heading br:not(.remaininSp), .basic-lead br:not(.remaininSp) {
    display: none; }
  .basic-heading + .basic-lead {
    margin-top: 2rem;
    padding-top: 2.5rem; }
  .basic-heading--centered + .basic-lead {
    padding-top: 0;
    text-align: justify;
    margin-top: 0.8rem; }
  .basic-heading--centered + .basic-lead > br {
    display: none; } }

@media screen and (max-width: 560px) {
  .basic-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    text-align: left; }
  .basic-lead {
    font-family: "游ゴシック",Yu Gothic,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,sans-serif;
    font-size: 1.5rem;
    line-height: 1.9;
    color: #6a6a6c; }
  .basic-heading + .basic-lead {
    margin-top: 0.7rem;
    padding-top: 0; }
  .basic-heading + .basic-lead:before {
    content: none; } }

.feature__txtBlock.-second {
  margin-top: 50px; }

@media screen and (max-width: 770px) {
  .feature__txtBlock.-second {
    margin-top: 20px; } }

.usage {
  position: relative;
  padding-top: 4rem;
  margin-top: 140px !important; }

.usage:before {
  content: "";
  display: block;
  width: 100%;
  height: 66rem;
  position: absolute;
  top: -20rem;
  z-index: 0;
  background-color: #FFF; }

@media screen and (min-width: 1501px) {
  .usage:before {
    background-color: transparent; } }

.usage .sectionTitle {
  margin-left: 4.5rem;
  position: absolute;
  top: 0;
  left: calc(( 100% - 80%) / 2);
  z-index: 2; }

@media screen and (max-width: 770px) {
  .usage {
    margin-top: 6rem !important; }
  .usage:before {
    height: 26rem;
    top: -6rem; } }

@media screen and (max-width: 560px) {
  .usage {
    padding-top: 0; }
  .usage .sectionTitle {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 0 5rem; } }

.usageIntro {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  padding-bottom: 8rem;
  position: relative;
  z-index: 1; }

.usageIntro__img {
  width: 100%; }

.usageIntro__img > img {
  display: block;
  width: 100%; }

.usageIntro__txt {
  background-color: #FFF;
  padding: 8rem 8rem; }

.usageIntro .mod-planContent {
  width: calc((100% - (12 - 1)*0px)/12*10 + (10 - 1)*0px);
  margin: 6rem auto 0; }

@media screen and (max-width: 1200px) {
  .usageIntro .mod-planContent {
    width: 100%; } }

@media screen and (max-width: 960px) {
  .usageIntro {
    padding-bottom: 0; }
  .usageIntro__txt {
    padding: 6rem 6rem 8rem; } }

@media screen and (max-width: 770px) {
  .usageIntro {
    padding: 0; }
  .usageIntro .mod-planContent {
    margin: 6rem 0 0;
    padding-top: 0;
    width: 100%;
    border-bottom: none; }
  .usageIntro .mod-planContent__title {
    display: block;
    text-align: center;
    position: static;
    transform: none;
    margin-bottom: 0.5rem; }
  .usageIntro .mod-planContent:before {
    content: none; }
  .usageIntro__txt {
    padding-bottom: 6rem; } }

@media screen and (max-width: 560px) {
  .usageIntro {
    width: 85%; }
  .usageIntro__txt {
    padding: 3rem 2.5rem; }
  .usageIntro .mod-planContent {
    margin: 4.5rem 0 0;
    padding-bottom: 1.5rem; } }

.planViewer {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 8rem;
  position: relative; }

.planViewer__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #DC8487;
  border-bottom: 1px solid #DC8487;
  padding: 2.3rem 1rem 2rem;
  margin-bottom: 2rem; }

.planViewer__title {
  font-size: 2.2rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  line-height: 1;
  color: #CB3A3E; }

.planViewer__ctl {
  display: flex;
  align-items: center;
  position: relative;
  border-left: 1px solid #CB3A3E;
  padding-left: 4rem; }

.planViewer__ctl:after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-bottom: 1px solid #CB3A3E;
  border-left: 1px solid #CB3A3E;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-70%) rotate(-45deg);
  pointer-events: none; }

.planViewer__ctl > label {
  font-size: 1.7rem;
  line-height: 1;
  color: #CB3A3E;
  position: relative;
  padding-right: 2.7rem;
  margin-right: 2.7rem; }

.planViewer__ctl > label:before {
  content: "";
  display: inline-block;
  width: 1.69rem;
  height: 1.5rem;
  margin-right: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/common/icon-setting.svg);
  transform: translateY(10%); }

.planViewer__ctl > label:after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1px;
  background-color: #CB3A3E;
  position: absolute;
  top: 50%;
  right: 0; }

.planViewer__ctl > select {
  border: none;
  outline: none;
  text-indent: 0.01px;
  text-overflow: '';
  background: none transparent;
  vertical-align: middle;
  font-size: 1.7rem;
  color: #CB3A3E;
  appearance: initial;
  border-radius: 0;
  border: none;
  width: 100px;
  height: 1em;
  line-height: 1; }

.planViewer__ctl > select option {
  background-color: #FFF;
  color: #CB3A3E; }

.planViewer__ctl > select::-ms-expand {
  display: none; }

.planViewer__ctl > select::-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #828c9a; }

.planViewer__ctl > select option {
  font-size: 1.8rem;
  color: #CB3A3E; }

.planViewer__next, .planViewer__prev {
  display: block;
  width: 5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  outline: none;
  cursor: pointer; }

.planViewer__next:before, .planViewer__prev:before {
  content: "";
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border-bottom: 1px solid #CB3A3E;
  border-right: 1px solid #CB3A3E;
  position: absolute;
  top: 50%; }

.planViewer__next {
  right: 0;
  transform: translateX(100%); }

.planViewer__next:before {
  right: 50%;
  transform: translate(50%, -50%) rotate(-45deg); }

.planViewer__prev {
  left: 0;
  transform: translateX(-100%); }

.planViewer__prev:before {
  left: 50%;
  transform: translate(-50%, -50%) rotate(135deg); }

.planViewer__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2.2rem; }

.planViewer__footer .mod-arrowBtn {
  flex: auto 0 0; }

.planViewer__iconSample {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.planViewer__iconSampleTitle {
  flex: auto 0 0;
  display: flex;
  align-items: center;
  padding: 0.2rem 0 0 0.8rem;
  height: 2.6rem;
  background-color: #8F8F8F;
  font-size: 1.3rem;
  line-height: 1;
  color: #FFF;
  position: relative;
  margin-right: 1.5rem; }

.planViewer__iconSampleTitle:after {
  content: "";
  display: block;
  width: 0;
  hright: 0;
  border-color: transparent transparent transparent #8F8F8F;
  border-width: 1.3rem 0 1.3rem 1.5rem;
  border-style: solid;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%); }

.planViewer__icons {
  display: flex;
  border: none;
  width: inherit;
  padding: 0;
  margin: 0 0 0 2.5rem; }

.planViewer__icons .mod-planIcon:before {
  background-color: #8F8F8F; }

.planViewer__icons > .mod-planIcon {
  flex: auto 1 1;
  padding: 0.8rem 0 0.8rem 3rem !important; }

.planViewer > .mod-notation {
  width: auto;
  margin-top: 1rem; }

@media screen and (max-width: 635px) {
  .planViewer {
    margin-top: 6rem; }
  .planViewer__title {
    flex: 100% 0 0;
    padding-left: 0.7rem; }
  .planViewer__nav {
    flex-wrap: wrap;
    padding: 2.3rem 0 0; }
  .planViewer__ctl {
    padding: 1.5rem 1rem 1rem;
    border-left: none;
    margin-top: 2rem;
    margin-left: 0;
    flex: 100% 0 0;
    position: relative; }
  .planViewer__ctl > label, .planViewer__ctl > select {
    color: #d7565a; }
  .planViewer__ctl:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.1rem;
    background-image: -moz-linear-gradient(90deg, #CB3A3E 0, #CB3A3E 0.1rem, transparent 0.1rem, transparent 0.4rem, #CB3A3E 0.4rem, #CB3A3E 0.5rem, transparent 0.5rem);
    background-image: -webkit-linear-gradient(90deg, #CB3A3E 0, #CB3A3E 0.1rem, transparent 0.1rem, transparent 0.4rem, #CB3A3E 0.4rem, #CB3A3E 0.5rem, transparent 0.5rem);
    background-image: linear-gradient(90deg, #CB3A3E 0, #CB3A3E 0.1rem, transparent 0.1rem, transparent 0.4rem, #CB3A3E 0.4rem, #CB3A3E 0.5rem, transparent 0.5rem);
    background-repeat: repeat-x;
    background-size: .4rem 100%; }
  .planViewer__ctl > select {
    flex: auto 1 1; }
  .planViewer__footer {
    flex-direction: column;
    align-items: flex-start; }
  .planViewer__footer .mod-arrowBtn {
    align-self: center;
    margin-top: 3rem; }
  .planViewer__iconSample {
    flex: 100% 0 0;
    justify-content: flex-start; } }

@media screen and (max-width: 560px) {
  .planViewer {
    width: 85%; }
  .planViewer__footer {
    margin-top: 1.5rem; }
  .planViewer__footer .mod-planIcon {
    font-size: 1.2rem; }
  .planViewer__footer .mod-planIcon + .mod-planIcon {
    margin-left: 0.5rem; } }

.planViewer__icons {
  margin-left: 1.5rem; }

.option {
  margin-top: 14rem; }

@media screen and (max-width: 770px) {
  .option {
    margin-top: 6rem; } }

@media screen and (max-width: 560px) {
  .option {
    margin-top: 5rem; } }

.optionIntro {
  width: calc(100% - 5.6rem);
  box-sizing: border-box;
  background-color: #FFF;
  padding: 8rem 8rem 14rem; }

.optionIntro > .basic-lead, .optionIntro > .mod-optionDesc {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto; }

.optionIntro .basic-lead + .mod-optionDesc {
  margin-top: 2rem; }

@media screen and (max-width: 960px) {
  .optionIntro {
    width: 100%;
    padding-bottom: 8rem; }
  .optionIntro .basic-lead + .mod-optionDesc {
    margin-top: 0; } }

@media screen and (max-width: 770px) {
  .optionIntro {
    width: 100%;
    padding: 8rem calc(( 100% - 85%) / 2) 13rem; }
  .optionIntro .mod-optionDesc {
    display: none; } }

@media screen and (max-width: 560px) {
  .optionIntro {
    padding: 3.5rem 3rem 14.5rem;
    margin-bottom: -3rem; } }

.optionSample {
  display: flex;
  flex-direction: column;
  align-items: center; }

.optionSample__box {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%; }

.optionSample__box + .optionSample__box {
  margin-top: 5.7rem; }

.optionSample__titleBox {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5rem;
  z-index: 1;
  background-color: #CB3A3E;
  padding: 2rem;
  color: #FFF; }

.optionSample__titleBox:after {
  content: "";
  display: block;
  width: 0.1rem;
  height: 2.5rem;
  background-color: #CB3A3E;
  position: absolute;
  bottom: 0.1rem;
  z-index: 0;
  transform-origin: center 0; }

.optionSample__num {
  flex: auto 0 0;
  font-family: "Avenir W01",sans-serif;
  font-size: 1.8rem;
  line-height: 1; }

.optionSample__num > span {
  display: inline-block;
  font-size: 1rem;
  border-bottom: 1px solid #FFF;
  padding-bottom: 0.3rem;
  vertical-align: bottom;
  box-sizing: border-box;
  transform: translateY(-18%);
  margin-right: 0.6rem; }

.optionSample__title {
  font-size: 1.9rem;
  line-height: 1;
  font-weight: normal; }

.optionSample__num + .optionSample__title {
  margin-left: 1rem; }

.optionSample__img {
  background-color: #EEE;
  position: relative;
  z-index: 0; }

.optionSample__img > img {
  display: block;
  width: 100%; }

.optionSample__txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) ) * 1);
  border-top: 1px solid #CB3A3E;
  border-bottom: 1px solid #CB3A3E;
  padding: 3rem 0;
  transform: translateY(10%); }

.optionSample__lead {
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 3rem; }

.optionSample__detail {
  display: flex;
  align-items: center; }

.optionSample__detailItem a {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #CB3A3E;
  position: relative;
  padding: 2rem 3.5rem 1.6rem;
  border-radius: 5rem;
  background-color: #FFF; }

.optionSample__detailItem + .optionSample__detailItem {
  position: relative;
  margin-left: 4rem; }

.optionSample__detailItem + .optionSample__detailItem:before, .optionSample__detailItem + .optionSample__detailItem:after {
  content: "";
  display: block;
  width: 1.6rem;
  height: 0.4rem;
  background-color: #CB3A3E;
  position: absolute;
  top: 50%;
  left: -2rem; }

.optionSample__detailItem + .optionSample__detailItem:before {
  transform: translate(-50%, -50%); }

.optionSample__detailItem + .optionSample__detailItem:after {
  transform: translate(-50%, -50%) rotate(90deg); }

.optionSample__detailTitle {
  font-size: 1.5rem;
  font-family: "Avenir W01",sans-serif;
  line-height: 1;
  color: #CB3A3E;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 0.5rem;
  letter-spacing: 0.1em; }

.optionSample__detailTitle > span {
  position: relative;
  z-index: 1; }

.optionSample__detailTitle:before {
  content: "";
  display: block;
  background-image: url(/img/common/bg.jpg);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  z-index: 0;
  transform: translateY(-50%); }

.optionSample__detailName {
  font-size: 1.5rem;
  color: #000; }

.optionSample__detailPrice {
  font-size: 1.5rem;
  color: #CB3A3E;
  margin-top: 0.6rem; }

.optionSample__detailMore {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #d35a5e;
  margin-top: 0.7rem;
  position: relative;
  border-bottom: 1px solid #DC8487;
  padding-bottom: 0.3rem; }

.optionSample__detailMore:after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
  border-bottom: 1px solid #CB3A3E;
  border-left: 1px solid #CB3A3E;
  margin-left: 0.4rem;
  transform: translateY(-2%) rotate(-135deg); }

.optionSample__total {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem; }

.optionSample__totalTitle {
  display: inline-block;
  background-color: #CB3A3E;
  color: #FFF;
  padding: 1rem;
  font-family: "Avenir W01",sans-serif;
  line-height: 1;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.1em; }

.optionSample__totalNum {
  font-size: 2.8rem;
  line-height: 1;
  font-family: "Avenir W01",sans-serif;
  position: relative;
  margin-left: 1rem; }

.optionSample__totalNum > span {
  position: relative;
  z-index: 1; }

.optionSample__totalNum:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.9rem;
  z-index: 0;
  transform: translateY(30%);
  background-color: #CB3A3E;
  opacity: 0.3; }

.optionSample__totalNote {
  flex: 100% 0 0;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1;
  margin-top: 1rem;
  color: #CB3A3E; }

.optionSample__totalNote:before {
  content: "※"; }

.optionSample__box:nth-child(1) {
  margin-top: -3.5rem; }

.optionSample__box:nth-child(1) .optionSample__titleBox {
  top: 7rem;
  left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  transform: translateX(-50%); }

.optionSample__box:nth-child(1) .optionSample__titleBox:after {
  left: 50%;
  transform: translate(3rem, 100%) rotate(45deg); }

.optionSample__box:nth-child(1) .optionSample__img {
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) ) * 1);
  margin-left: calc(( 100% - 80%) / 2); }

.optionSample__box:nth-child(1) .optionSample__txt {
  margin-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); }

.optionSample__box:nth-child(2) {
  flex-direction: row-reverse; }

.optionSample__box:nth-child(2) .optionSample__titleBox {
  right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  transform: translateX(50%); }

.optionSample__box:nth-child(2) .optionSample__titleBox:after {
  right: 50%;
  transform: translate(-3rem, 100%) rotate(-45deg); }

.optionSample__box:nth-child(2) .optionSample__img {
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1); }

.optionSample__box:nth-child(2) .optionSample__txt {
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) ) * 1);
  margin-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); }

.optionSample__box:nth-child(2) + .mod-arrowBtn {
  margin-top: 5rem; }

@media screen and (max-width: 1100px) {
  .optionSample__box {
    align-items: flex-start; }
  .optionSample__txt {
    transform: none;
    margin-top: 6rem; }
  .optionSample__titleBox {
    white-space: nowrap; }
  .optionSample__box:nth-child(1) .optionSample__titleBox, .optionSample__box:nth-child(2) .optionSample__titleBox {
    top: 3.5rem; }
  .optionSample__box:nth-child(1) {
    margin-top: 7rem; }
  .optionSample__box:nth-child(1) .optionSample__img {
    width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 4 + ( 0px * ( 4 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
    margin-left: 0; }
  .optionSample__box:nth-child(1) .optionSample__txt {
    width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 7 + ( 0px * ( 7 - 1 ) ) ) * 1); }
  .optionSample__box:nth-child(1) .optionSample__titleBox {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%); }
  .optionSample__box:nth-child(1) .optionSample__titleBox:after {
    left: 12rem;
    transform: translate(0, 100%) rotate(45deg); }
  .optionSample__box:nth-child(2) .optionSample__img {
    width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 4 + ( 0px * ( 4 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1); }
  .optionSample__box:nth-child(2) .optionSample__txt {
    width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 7 + ( 0px * ( 7 - 1 ) ) ) * 1); }
  .optionSample__box:nth-child(2) .optionSample__titleBox {
    top: 0;
    right: 50%;
    transform: translate(50%, -50%); }
  .optionSample__box:nth-child(2) .optionSample__titleBox:after {
    right: 12rem;
    transform: translate(0, 100%) rotate(-45deg); }
  .optionSample__box + .optionSample__box {
    margin-top: 8rem; } }

@media screen and (max-width: 960px) {
  .optionSample__lead {
    font-size: 1.6rem; } }

@media screen and (max-width: 770px) {
  .optionSample__box {
    display: block;
    width: 85%;
    margin: auto; }
  .optionSample__box + .optionSample__box {
    margin-top: 5rem; }
  .optionSample__box:nth-child(1) {
    margin-top: -9rem; }
  .optionSample__img {
    width: 100% !important;
    margin: 0 !important; }
  .optionSample__img:before {
    padding-top: 60% !important; }
  .optionSample__titleBox {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: auto !important;
    transform: none !important;
    margin: 0 0 0.8rem !important;
    padding: 1.3rem 1.3rem 1rem; }
  .optionSample__titleBox:after {
    height: 2rem;
    left: 50% !important;
    transform: translate(-50%, 100%) rotate(45deg) !important; }
  .optionSample__title {
    font-size: 1.5rem;
    line-height: 1.3;
    letter-spacing: 0; }
  .optionSample__num {
    font-size: 1.6rem;
    letter-spacing: 0; }
  .optionSample__num > span {
    font-size: 0.6rem;
    border-bottom-color: #FADEE0;
    padding-bottom: 0.2rem; }
  .optionSample__txt {
    width: 100% !important;
    margin: 0 !important;
    background-color: transparent;
    padding: 3.2rem 0 4rem; }
  .optionSample__lead {
    font-size: 1.5rem;
    margin-bottom: 2rem; }
  .optionSample__detailItem > a {
    padding: 1.5rem 2rem 1.2rem; }
  .optionSample__detailName {
    font-size: 1.3rem; }
  .optionSample__detailPrice {
    margin-top: 0.3rem; }
  .optionSample__detailMore {
    margin-top: 0.5rem; }
  .optionSample__total {
    margin-top: 1.5rem; }
  .optionSample__totalTitle {
    font-size: 1rem;
    padding: 0.8rem 0.8rem;
    transform: translateY(10%); }
  .optionSample__totalNum {
    font-size: 2.5rem; }
  .optionSample__box + .optionSample__box {
    margin-top: 2.5rem; } }

@media screen and (max-width: 560px) {
  .optionSample__titleBox {
    padding: 1rem 1.3rem; }
  .optionSample__num > span {
    transform: translateY(-35%); } }

.contentsIntro {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.contentsIntro__lead {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative; }

.contentsIntro__img {
  flex-basis: calc( 80% / 12 * 7 + ( 100% - 80%)/2 - 5.6rem);
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #EEE;
  position: relative;
  z-index: 0; }

.contentsIntro__img > img {
  display: block;
  width: 100%; }

.contentsIntro__txt {
  flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  max-width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  flex-shrink: 0;
  background-color: #FFF;
  padding-top: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  padding-bottom: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  box-sizing: border-box;
  position: relative;
  z-index: 1; }

.contentsIntro__txt .basic-lead + .mod-arrowBtn {
  margin-top: 5rem; }

.contentsIntro .sectionTitle {
  position: absolute;
  top: 0;
  z-index: 2; }

.contentsIntro .exampleSlide + .mod-arrowBtn {
  align-self: flex-end;
  margin-top: 5.6rem;
  margin-right: calc(( 100% - 80%) / 2); }

.contentsIntro:not(.contentsIntro--reversed) .contentsIntro__lead {
  padding-top: 8rem; }

.contentsIntro:not(.contentsIntro--reversed) .contentsIntro__img {
  margin-left: 5.6rem; }

.contentsIntro:not(.contentsIntro--reversed) .contentsIntro__txt {
  margin-top: 12rem;
  margin-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * -1);
  padding-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  padding-right: calc(( 100% - 80%) / 2); }

.contentsIntro:not(.contentsIntro--reversed) .sectionTitle {
  right: 5.6rem; }

.contentsIntro--reversed .contentsIntro__lead {
  flex-direction: row-reverse; }

.contentsIntro--reversed .contentsIntro__img {
  margin-right: 5.6rem; }

.contentsIntro--reversed .contentsIntro__txt {
  margin-top: 20rem;
  margin-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * -1);
  padding-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  padding-left: calc(( 100% - 80%) / 2); }

.contentsIntro--reversed .sectionTitle {
  left: 5.6rem;
  right: auto; }

@media screen and (max-width: 1100px) {
  .contentsIntro__img {
    align-items: center;
    margin-bottom: 5rem; }
  .contentsIntro__txt .basic-lead {
    line-height: 1.8; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__txt {
    margin-top: 9rem; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__img {
    margin-top: 3rem; }
  .contentsIntro--reversed .contentsIntro__txt {
    padding-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); } }

@media screen and (max-width: 960px) {
  .contentsIntro__txt .basic-heading {
    font-size: 2rem; }
  .contentsIntro__txt .basic-lead {
    font-size: 1.6rem; }
  .contentsIntro__img {
    flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 7 + ( 0px * ( 7 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1); }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__lead {
    padding-top: 9rem; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__txt {
    padding-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
    margin-top: 6rem; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__img {
    margin-top: 0;
    margin-left: 0; }
  .contentsIntro--reversed .contentsIntro__img {
    margin-right: 0;
    margin-top: 5rem; }
  .contentsIntro--reversed .contentsIntro__txt {
    margin-top: 16rem;
    padding-left: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1); } }

@media screen and (max-width: 770px) {
  .contentsIntro {
    width: 85%;
    margin: auto; }
  .contentsIntro__lead {
    display: block;
    padding-top: 3rem !important; }
  .contentsIntro__img {
    width: 100%;
    margin: 0; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__img, .contentsIntro--reversed .contentsIntro__img {
    width: 100%;
    margin: 0; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__img:before, .contentsIntro--reversed .contentsIntro__img:before {
    padding-top: 60%; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__txt, .contentsIntro--reversed .contentsIntro__txt {
    margin: 0;
    width: 100%;
    max-width: inherit;
    padding: 3rem 5vw; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__txt .basic-lead + .mod-arrowBtn, .contentsIntro--reversed .contentsIntro__txt .basic-lead + .mod-arrowBtn {
    margin-top: 2rem; }
  .contentsIntro .exampleSlide + .mod-arrowBtn {
    margin: 1rem 0 0;
    align-self: center; } }

@media screen and (max-width: 560px) {
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__lead, .contentsIntro--reversed .contentsIntro__lead {
    padding-top: 0 !important; }
  .contentsIntro:not(.contentsIntro--reversed) .contentsIntro__txt .basic-lead + .mod-arrowBtn, .contentsIntro--reversed .contentsIntro__txt .basic-lead + .mod-arrowBtn {
    margin-top: 1.5rem; } }

.exampleSlide {
  display: flex;
  align-items: flex-start;
  width: 80%;
  max-width: 1200px;
  margin: 5.6rem auto 0; }

.exampleSlide__cont {
  flex-basis: calc(100% - 5.6rem*2);
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden; }

.exampleSlide__cont > .swiper-container > .swiper-wrapper > .swiper-slide {
  align-self: stretch;
  height: 100%; }

.exampleSlide__cont > .swiper-container > .swiper-wrapper > .swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important; }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  background-image: url(/img/common/bg.jpg); }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide {
  position: relative;
  display: block; }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item > img {
  display: block;
  width: 100%; }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__name {
  font-size: 19rem;
  color: #2A2A2B;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.3;
  margin-top: 0.5rem; }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__option {
  color: #CB3A3E;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #DC8487;
  margin-top: 0.7rem; }

.exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__option--isNot {
  color: #666;
  border-bottom-color: #CFCFCF; }

.exampleSlide__nav {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5.6rem; }

.exampleSlide__category {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1.8rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal; }

.exampleSlide__categoryCurrent {
  flex: auto 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  background-color: #CB3A3E;
  width: 100%;
  padding: 2rem 0;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  min-height: 5rem;
  position: relative; }

.exampleSlide__categoryCurrent > span {
  writing-mode: vertical-rl; }

.exampleSlide__categoryCurrent:after {
  content: "";
  display: block;
  width: 1px;
  height: 0.8rem;
  background-color: #FFF;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

.exampleSlide__categoryControl {
  flex: auto 0 0;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #CB3A3E;
  border: 1px solid #CB3A3E;
  background-color: #FFF;
  width: 100%;
  padding: 2rem 0;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  min-height: 5rem;
  box-sizing: border-box;
  position: relative;
  outline: none; }

.exampleSlide__categoryControl span {
  writing-mode: vertical-rl; }

.exampleSlide__categoryControl:before {
  content: "";
  display: block;
  width: 1px;
  height: 0.8rem;
  background-color: #CB3A3E;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.exampleSlide__categoryControl:after {
  content: "";
  display: block;
  height: 0.6rem;
  width: 1.9rem;
  margin-top: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/sitetop/arrow-tochange_s.svg); }

.exampleSlide__slideControls {
  align-self: flex-end; }

.exampleSlide__slideControl {
  width: 5.6rem;
  height: 5.6rem;
  background-color: #CB3A3E;
  border: none;
  display: block;
  position: relative; }

.exampleSlide__slideControl:before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF; }

.exampleSlide__slideControl + .exampleSlide__slideControl {
  margin-top: 1px; }

.exampleSlide__prev:before {
  left: 50%;
  transform: translate(-30%, -50%) rotate(45deg); }

.exampleSlide__next:before {
  right: 50%;
  transform: translate(30%, -50%) rotate(-135deg); }

@media screen and (min-width: 961px) {
  .exampleSlide__cont .swiper-pagination {
    display: none; } }

@media screen and (max-width: 960px) {
  .exampleSlide {
    display: grid;
    grid-template-rows: 5.6rem 3rem auto;
    width: 85%;
    max-width: inherit; }
  .exampleSlide__cont {
    grid-row: 3;
    flex-basis: 100%; }
  .exampleSlide__cont .swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 2rem;
    padding-bottom: 3rem; }
  .exampleSlide__cont .swiper-pagination-bullet-active {
    background-color: #CB3A3E; }
  .exampleSlide__cont .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 0.5rem; }
  .exampleSlide__nav {
    gird-row: 1;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
    margin-left: 0; }
  .exampleSlide__category {
    flex: auto 1 0;
    align-self: stretch;
    align-items: stretch;
    flex-direction: row; }
  .exampleSlide__categoryCurrent {
    flex-direction: row;
    flex: auto 1 1;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.8rem; }
  .exampleSlide__categoryCurrent:after {
    width: 0.8rem;
    height: 0.1rem;
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%); }
  .exampleSlide__categoryCurrent span {
    writing-mode: horizontal-tb; }
  .exampleSlide__categoryControl {
    flex: auto 1 1;
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.8rem 0 2.5rem;
    background-color: transparent; }
  .exampleSlide__categoryControl:before {
    width: 0.8rem;
    height: 0.1rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .exampleSlide__categoryControl:after {
    margin-top: 0;
    margin-left: 1rem; }
  .exampleSlide__categoryControl span {
    writing-mode: horizontal-tb; }
  .exampleSlide__slideControls {
    display: none; } }

@media screen and (max-width: 770px) {
  .exampleSlide {
    width: 100%; } }

@media screen and (max-width: 560px) {
  .exampleSlide {
    margin-top: 3rem;
    grid-template-rows: 5.6rem 1rem auto; }
  .exampleSlide__nav {
    height: 4.5rem; }
  .exampleSlide__categoryCurrent, .exampleSlide__categoryControl {
    min-height: unset;
    font-size: 1.6rem; }
  .exampleSlide__cont .swiper-pagination {
    padding-bottom: 0.5rem; } }

.locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:before {
  content: none; }

.locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item > img {
  position: static;
  transform: none;
  height: auto;
  width: 100%; }

@media screen and (min-width: 961px) {
  .locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
    width: calc(( 100% - 5.6rem*2 - 1px) / 3); }
  .locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(3n+1)) {
    margin-left: 5.6rem; }
  .locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(-n+3)) {
    margin-top: 5.6rem; } }

@media screen and (max-width: 960px) {
  .locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
    width: calc(( 100% - 1rem*2 - 1px) / 3); }
  .locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(3n+1)) {
    margin-left: 1rem; }
  .locationExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(-n+3)) {
    margin-top: 1rem; } }

@media screen and (min-width: 961px) {
  .hairExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
    width: calc(( 100% - 5.6rem*3 - 1px) / 4); }
  .hairExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(4n+1)) {
    margin-left: 5.6rem; }
  .hairExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(-n+4)) {
    margin-top: 5.6rem; } }

@media screen and (max-width: 960px) {
  .hairExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
    width: calc(( 100% - 1rem*3 - 1px) / 4); }
  .hairExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(4n+1)) {
    margin-left: 1rem; }
  .hairExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(-n+4)) {
    margin-top: 1rem; } }

.costumeExample .exampleSlide__item {
  position: relative; }

.costumeExample .exampleSlide__item:before {
  content: "";
  display: block;
  padding-top: 135%; }

.costumeExample .exampleSlide__item > img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@media screen and (min-width: 961px) {
  .costumeExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
    width: calc(( 100% - 5.6rem*3 - 1px) / 4); }
  .costumeExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(4n+1)) {
    margin-left: 5.6rem; }
  .costumeExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(-n+4)) {
    margin-top: 5.6rem; } }

@media screen and (max-width: 960px) {
  .costumeExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item {
    width: calc(( 100% - 1rem*3 - 1px) / 4); }
  .costumeExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(4n+1)) {
    margin-left: 1rem; }
  .costumeExample .exampleSlide__cont .swiper-container .swiper-container .swiper-slide .exampleSlide__item:not(:nth-child(-n+4)) {
    margin-top: 1rem; } }

.aboutTopbeauty {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 8rem; }

@media screen and (max-width: 960px) {
  .aboutTopbeauty {
    display: block;
    width: 80%;
    margin: 0 auto; } }

.aboutTopbeauty__img {
  flex-basis: calc( ( (75% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 75% ) / 2 ) * 1);
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  background-color: #EEE; }

.aboutTopbeauty__img > img {
  display: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%; }

.aboutTopbeauty__txt {
  flex-basis: calc( ( (75% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) ) * 1);
  max-width: calc( ( (75% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) ) * 1);
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  background-color: #FFF;
  padding: calc( ( (75% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  box-sizing: border-box; }

@media screen and (max-width: 960px) {
  .aboutTopbeauty__txt {
    max-width: inherit; } }

.aboutTopbeauty__txt .cap {
  font-size: 1.5rem;
  line-height: 2; }

.aboutTopbeauty__txt .cap + .mod-arrowBtn {
  margin-top: 3rem; }

.aboutTopbeauty__txt > .aboutTopbeauty__txt__heading, .aboutTopbeauty__txt > .cap {
  width: 100%; }

.aboutTopbeauty__heading {
  font-size: 2.1rem;
  margin: 0 0 1rem;
  font-family: "Noto Serif JP",serif;
  font-weight: normal; }

.aboutTopbeauty__heading:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 0.35rem 0 0.35rem 0.8rem;
  border-style: solid;
  border-color: transparent transparent transparent #CB3A3E;
  vertical-align: middle;
  transform: translateY(-0.13em);
  margin-right: 0.5rem; }

@media screen and (max-width: 770px) {
  .aboutTopbeauty {
    width: 85%;
    margin: 3rem auto 1rem; }
  .aboutTopbeauty__txt {
    width: 100%;
    max-width: inherit;
    align-items: flex-start;
    padding: 3rem 3rem 5rem; }
  .aboutTopbeauty__txt .cap + .mod-arrowBtn {
    align-self: flex-start;
    margin-top: 1.5rem; } }

@media screen and (max-width: 560px) {
  .aboutTopbeauty__txt {
    padding-bottom: 4rem; }
  .aboutTopbeauty__heading {
    font-size: 1.9rem;
    margin-bottom: 0.5rem; }
  .aboutTopbeauty__cap {
    line-height: 1.5; } }

.currentCampaign {
  display: block;
  width: 80%;
  margin: 5rem auto 0; }

.currentCampaign__title {
  margin-bottom: 2rem; }

.currentCampaign__banner {
  display: block;
  width: 100%;
  background-image: url(/img/sitetop/current-campaign.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1.5rem;
  transition: opacity 0.5s linear;
  position: relative; }

.currentCampaign__banner:before {
  content: "";
  display: block;
  padding-top: 45%; }

.currentCampaign__banner:after {
  content: "";
  display: block;
  position: absolute;
  top: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  border: 2px solid #FFF;
  opacity: 0.4;
  border-radius: 0.9rem; }

.currentCampaign__banner:hover {
  opacity: 0.9;
  transition: opacity 0.3s linear; }

@media screen and (max-width: 960px) {
  .currentCampaign {
    width: 85%; } }

.photoIntro {
  position: relative; }

.photoIntro:after {
  content: "";
  display: block;
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 11 + ( 0px * ( 11 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
  background-color: #FFF;
  position: absolute;
  top: 5.6rem;
  left: 0;
  bottom: 3.5rem; }

.photoIntro > * {
  position: relative;
  z-index: 1; }

.photoIntro:after {
  content: "";
  display: block;
  position: absolute; }

.photoIntro .contentsIntro__lead {
  padding-top: 10.6rem; }

.photoIntro .contentsIntro__lead + .mod-simplePhotoGallery {
  margin-top: 12rem; }

.photoIntro .contentsIntro__txt {
  align-self: flex-end;
  background: none;
  margin-top: 0;
  margin-right: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 1 + ( 0px * ( 1 - 1 ) ) ) * 1);
  padding: 0;
  flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 5 + ( 0px * ( 5 - 1 ) ) ) * 1);
  margin-top: 15rem; }

.photoIntro .contentsIntro__img {
  align-self: stretch;
  flex-basis: calc( 80% / 12 * 6 + ( 100% - 80% - 5.6rem * 2 ) / 2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; }

.photoIntro .contentsIntro__img:before {
  content: "";
  display: block;
  padding-top: 150%; }

.photoIntro .sectionTitle {
  top: 0;
  left: calc(( 100% - 80%) / 2); }

.photoIntro .mod-simplePhotoGallery {
  width: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 12 + ( 0px * ( 12 - 1 ) ) ) * 1);
  margin: 8rem auto 0; }

@media screen and (max-width: 1100px) {
  .photoIntro .contentsIntro__img {
    flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1);
    margin: 0; } }

@media screen and (max-width: 960px) {
  .photoIntro .basic-heading > br {
    display: none; } }

@media screen and (max-width: 875px) {
  .photoIntro .contentsIntro__img {
    flex-basis: calc( ( (80% - 0px * ( 12 - 1 ) ) / 12 * 6 + ( 0px * ( 6 - 1 ) ) + ( 100% - 80% ) / 2 ) * 1); } }

@media screen and (max-width: 770px) {
  .photoIntro:after {
    content: none; }
  .photoIntro .contentsIntro__img:before {
    content: "";
    display: block;
    padding-top: 75%; }
  .photoIntro .contentsIntro__txt {
    padding: 3rem 5vw;
    background-color: #FFF;
    margin-top: 0; }
  .photoIntro .contentsIntro__lead + .mod-simplePhotoGallery {
    margin-top: 5rem;
    width: 100%; } }

@media screen and (max-width: 560px) {
  .photoIntro:after {
    content: none; }
  .photoIntro .contentsIntro__txt {
    flex-basis: 100%;
    background-color: #FFF;
    padding: 2.5rem 2.5rem 4rem; }
  .photoIntro .contentsIntro__lead + .mod-simplePhotoGallery {
    margin-top: 5rem; }
  .photoIntro .mod-simplePhotoGallery {
    width: 100%; }
  .photoIntro .sectionTitle {
    width: 100%; } }

.hairmakeIntro .sectionTitle {
  z-index: 2; }

.no1-cont {
  width: 100%;
  padding-bottom: 50px; }

@media screen and (max-width: 560px) {
  .no1-cont {
    padding-bottom: 40px; } }

@media screen and (max-width: 1500px) {
  .no1-cont {
    background-color: #fff; } }

.no1-img {
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
  padding-top: 50px;
  display: flex;
  justify-content: space-around; }

.no1-img__item {
  max-width: 300px;
  width: 25%; }

.anounce {
  background-color: #FFF;
  padding: 3rem 3rem 8rem; }

@media screen and (min-width: 1501px) {
  .anounce {
    background-color: transparent; } }

@media screen and (max-width: 770px) {
  .anounce {
    padding: 100px 0 0 0; } }

@media screen and (max-width: 560px) {
  .anounce {
    padding: 0; } }

.anounce__addTitle {
  font-size: 16px;
  text-align: center; }

.anounce:before {
  background-color: transparent; }

.anounce__inner {
  max-width: 700px;
  width: 100%; }

@media screen and (max-width: 770px) {
  .anounce__inner {
    width: 80%; } }

@media screen and (max-width: 560px) {
  .anounce__inner {
    width: 85%; } }

.anounce__btn {
  margin: 0 auto;
  max-width: 700px; }

@media screen and (max-width: 770px) {
  .anounce__btn {
    font-size: 1.4rem;
    font-size: 14px;
    line-height: 1.7; } }

@media screen and (max-width: 369px) {
  .anounce__btn {
    font-size: 12px; } }

.anounce__btn .br-sm {
  display: none; }

@media screen and (max-width: 770px) {
  .anounce__btn .br-sm {
    display: block; } }

.anounce__date {
  margin-top: 80px; }

@media screen and (max-width: 770px) {
  .anounce__date {
    margin-top: 60px; } }

.anounce .mod-simplePhotoGallery__viewmore > span:after, .anounce .mod-simplePhotoGallery__viewmore > span:before {
  content: none; }

.blogIndex {
  background: #fff; }

.blogIndex_container {
  display: flex;
  justify-content: space-between;
  gap: 8rem 3rem;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 64px 0; }

.blogIndex_container > * {
  padding-left: 2.2rem; }

@media screen and (max-width: 770px) {
  .blogIndex_container {
    flex-direction: column;
    width: 85%; }
  .blogIndex_container > * {
    padding-left: 1rem; } }

.blogIndex_secTtl {
  display: inline-block;
  margin-bottom: 2.2rem;
  margin-left: -2.2rem;
  padding: 1rem 2.6rem;
  border: 1px solid #CB3A3F;
  position: relative; }

@media screen and (max-width: 770px) {
  .blogIndex_secTtl {
    margin-left: -1rem;
    padding: 0.8rem 2rem; } }

.blogIndex_secTtl > span {
  display: block;
  color: #CB3A3F;
  font-size: 1.8rem;
  letter-spacing: 0.12em; }

@media screen and (max-width: 770px) {
  .blogIndex_secTtl > span {
    font-size: 1.6rem; } }

.blogIndex_secTtl::before, .blogIndex_secTtl::after, .blogIndex_secTtl > span::before, .blogIndex_secTtl > span::after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #fff;
  position: absolute;
  z-index: 2; }

.blogIndex_secTtl::before {
  top: -1px;
  left: 0; }

.blogIndex_secTtl::after {
  top: 0;
  right: -1px; }

.blogIndex_secTtl > span::before {
  left: -1px;
  bottom: 0; }

.blogIndex_secTtl > span::after {
  right: 0;
  bottom: -1px; }

.blogIndex_secTtl ._footLine {
  display: block;
  background: #CB3A3F;
  width: 30px;
  height: 1px;
  transform-origin: left center;
  transform: rotate(20deg);
  position: absolute;
  left: -1px;
  bottom: 0; }

.blogIndex_more {
  text-align: right;
  margin-top: 2.5rem; }

@media screen and (max-width: 770px) {
  .blogIndex_more {
    margin-top: 4rem; } }

.blogIndex_more a {
  font-size: 1.5rem;
  padding-bottom: 1.5rem; }

.blogIndex_news {
  max-width: 51rem;
  width: 48%; }

@media screen and (max-width: 770px) {
  .blogIndex_news {
    width: 100%;
    max-width: 100%; } }

.blogIndex_news ._list {
  margin-top: -0.8rem; }

.blogIndex_news ._list a {
  display: block;
  padding: 1.8rem 0 2.8rem;
  color: #343434;
  font-family: "Noto Serif JP",serif;
  background-image: linear-gradient(to right, #343434, #343434 2px, transparent 2px, transparent 12px);
  background-size: 14px 2px;
  background-position: left bottom;
  background-repeat: repeat-x; }

@media screen and (max-width: 770px) {
  .blogIndex_news ._list a {
    padding-bottom: 2.2rem; } }

.blogIndex_news ._time {
  font-size: 1.4rem;
  letter-spacing: 0.12em; }

@media screen and (max-width: 770px) {
  .blogIndex_news ._time {
    font-size: 1.3rem; } }

.blogIndex_news ._ttl {
  margin-top: 0.4rem;
  font-size: 1.8rem;
  letter-spacing: 0.12em;
  line-height: 1.5; }

@media screen and (max-width: 770px) {
  .blogIndex_news ._ttl {
    margin-top: 0.8rem;
    font-size: 1.6rem; } }

.blogIndex_campaign {
  max-width: 56rem;
  width: 52%; }

@media screen and (max-width: 770px) {
  .blogIndex_campaign {
    width: 100%;
    max-width: 100%; } }

.blogIndex_campaign ._thumbnail {
  padding-top: calc(268 / 540 * 100%);
  position: relative; }

.blogIndex_campaign ._thumbnail img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.campaignList {
  background-color: #FFF;
  padding-top: 60px;
  padding-bottom: 60px; }

@media screen and (max-width: 770px) {
  .campaignList {
    padding-bottom: 0; } }

.campaignList .usageIntro {
  padding-bottom: 0; }

.campaignList__title {
  border-top: 1px solid #dc8487;
  border-bottom: 1px solid #dc8487;
  padding: 2.3rem 1rem 2rem; }

.campaignList__title--text {
  color: #cb3a3e;
  font-size: 2.2rem;
  font-family: "Noto Serif JP",serif;
  font-weight: 400;
  line-height: 1; }

@media screen and (max-width: 635px) {
  .campaignList__title--text {
    padding-left: .7rem; } }

.campaignList__container {
  margin-top: 40px; }

@media screen and (max-width: 770px) {
  .campaignList__container {
    margin-top: 0; } }

@media screen and (max-width: 770px) {
  .campaignList .campaignSlide__main {
    padding: 30px 0; } }

.campaignList .campaignSlide-container .swiper-pagination {
  bottom: 0;
  padding-bottom: 1.5rem; }

.campaignList .campaignSlide-container .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #FFF;
  opacity: 1; }

.campaignList .campaignSlide-container .swiper-pagination-bullet-active {
  background-color: #CB3A3E;
  border-color: #CB3A3E; }

.campaignList .campaignSlide-container .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 0.5rem; }

@media screen and (min-width: 771px) {
  .campaignList__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    width: initial; } }

@media screen and (min-width: 771px) {
  .campaignList__itemsBlock {
    padding: 5px;
    width: 33.333%; } }

.campaignList__itemsLink {
  display: block; }

.campaignList__itemsImage {
  width: 100%; }

.cp_qa *, .cp_qa *:after, .cp_qa *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.cp_qa {
  border-top: 1px solid #1b2538; }

.cp_qa .cp_actab {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1px;
  color: #1b2538; }

.cp_qa .cp_actab input {
  position: absolute;
  opacity: 0; }

.cp_qa .cp_actab label {
  font-weight: bold;
  line-height: 1.6;
  position: relative;
  display: block;
  margin: 0 0 0 0;
  padding: 1em 2em 1em 1em;
  cursor: pointer;
  border-bottom: 1px solid #1b2538; }

.cp_qa .cp_actab label:hover {
  color: #CB3A3E; }

.cp_qa .cp_actab .cp_actab-content {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
  color: #ffffff;
  background: #CB3A3E; }

.cp_qa .cp_actab .cp_actab-content p {
  margin: 1em;
  line-height: 1.5; }

.cp_qa .cp_actab input:checked ~ label {
  color: #CB3A3E; }

.cp_qa .cp_actab input:checked ~ .cp_actab-content {
  max-height: 45em; }

.cp_qa .cp_actab label::after {
  line-height: 1.6;
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 3em;
  margin-top: -12.5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-align: center; }

.cp_qa .cp_actab input[type=checkbox] + label::after {
  content: '▼'; }

.cp_qa .cp_actab input[type=checkbox]:checked + label::after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg); }

.faq-contents {
  width: 80%;
  max-width: 960px;
  margin: 100px auto 50px;
  font-size: 18px;
  font-family: "Noto Serif JP",serif; }

.faq-contents h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #CB3A3E; }

.access {
  font-family: "Noto Serif JP", serif;
  margin-top: 100px; }

@media screen and (max-width: 770px) {
  .access {
    width: 80%;
    margin: auto; } }

.access h2 {
  margin-bottom: 40px;
  text-align: center;
  color: #CB3A3E;
  font-size: 30px; }

.access iframe {
  padding-left: 10px; }

@media screen and (max-width: 770px) {
  .access iframe {
    margin-top: 30px;
    padding-left: 0;
    width: 100% !important; } }

.access-flex {
  display: flex;
  align-items: center;
  justify-content: center; }

@media screen and (max-width: 770px) {
  .access-flex {
    flex-direction: column; } }

.access-cont {
  padding-right: 10px;
  width: 40%; }

@media screen and (max-width: 770px) {
  .access-cont {
    padding-right: 0;
    width: 100%; } }

.access-cont table {
  margin: auto; }

.access-cont td {
  font-size: 18px;
  padding: 12px;
  border-bottom: #CB3A3E 1px solid; }

@media screen and (max-width: 770px) {
  .access-cont td {
    font-size: 14px; } }

.access-cont td:first-child {
  font-weight: bold;
  border-right: #CB3A3E 1px solid;
  min-width: 110px; }

@media screen and (max-width: 560px) {
  .access-cont td:first-child {
    min-width: 90px; } }

.access-cont__linkItem {
  color: #2A2A2B; }

.access-cont__linkItem:visited {
  color: #2A2A2B; }

.SP {
  display: none; }

@media screen and (max-width: 770px) {
  .SP {
    display: block; } }

.reason {
  position: relative;
  padding-top: 4rem;
  margin-top: 140px !important; }

@media screen and (max-width: 770px) {
  .reason {
    margin-top: 6rem !important; } }

@media screen and (max-width: 560px) {
  .reason {
    padding-top: 0; } }

.reason .sectionTitle {
  margin-right: 4.5rem;
  position: absolute;
  top: 0;
  right: 10%;
  z-index: 2; }

.reason__img {
  width: 100%; }

.reasonIntro {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  padding-bottom: 8rem;
  position: relative;
  z-index: 1; }

.reasonIntro__img > img {
  display: block;
  width: 100%;
  height: 450px;
  object-fit: cover; }

@media screen and (max-width: 560px) {
  .reasonIntro__img > img {
    height: auto; } }

.reasonIntro__txt_box {
  margin-bottom: 30px; }

.reasonIntro__txt {
  background-color: #fff;
  padding: 8rem; }

@media screen and (max-width: 560px) {
  .reasonIntro__txt {
    padding: 3rem 2.5rem; } }

.reasonIntro__txt span {
  font-size: 2rem; }

@media screen and (max-width: 960px) {
  .reasonIntro__txt span {
    display: block; } }

@media screen and (max-width: 560px) {
  .reasonIntro__txt span {
    font-size: 1.5rem; } }
